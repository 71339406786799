<template>

  <b-card>

    <b-row>
      <b-col cols="12">

        <pop-order-detail-card 
          :pop-order-id="popOrderId"
          class="mt-2 pt-75"
        />

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import PopOrderDetailCard from './PopOrderDetailCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

    PopOrderDetailCard
  },
  data() {
    return {
      popOrderId: null,
    }
  },
  created() {
    this.popOrderId = router.currentRoute.params.pop_order_id
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>